import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import "./ParkRules.css";
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {BulletPoint} from "../utils";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

const IndividualSiteInfoCard = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: "#432628" }} aria-label="recipe">
                        1
                    </Avatar>
                }
                title={
                    <Typography variant="h5">
                        Individual Site Info
                    </Typography>
                }
                action={
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    </ExpandMore>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <BulletPoint description="Our resort is an age 55+ and older community. The main person on the
                        lease/rental agreement/site space agreement MUST be at least 55+
                        years old and no one under the age of 21 is allowed to be a full-time
                        resident. We do not allow anyone under the age of 55+ years old to be
                        the main renter. They must live with someone who is 55+ years old and
                        cannot live alone. We do not allow transfers of site lease agreements to
                        family members/ friends for our tenants who own their homes. Guests
                        are allowed to stay a maximum of 3 days unless arrangements have
                        been made with park management. All prospective lessees are subject
                        to credit reports, background checks and reference checks. Day
                        campers must be at least 21+ years old as sole occupants. All ages are
                        welcome as guests and day campers as long as they are with someone
                        who is 21+. Day campers are allowed to stay up to 30 days." />
                    <BulletPoint description="Nothing is to be stored on decks or around sites within the view of other
                        tenants or prospective tenants, except grills, firewood, undamaged outdoor furniture,
                        plants, and tasteful decorations. No junk storage, household furniture, ladders, cardboard,
                        gas containers, lawn maintenance equipment, bicycles, totes, or building material etc. is
                        allowed. Hanging twinkling/solid lights ARE allowed if the brightness does NOT disturb your
                        neighbors. All holiday décor must be removed within 10 days of the holiday." />
                    <BulletPoint description="Trash must NEVER be stored on porches or sites whether in trash bags or
                        cans, due to bears, racoons and other wildlife that will get into it. If your trash is found
                        strewn about the park or your site, you will be fined $25.00 for clean-up charge. Please use the
                        dumpsters provided for household trash and NEVER put any large items such as furniture, metal,
                        tables, chairs etc. into the dumpsters. If you are caught adding large items, you will be
                        charged a $100.00 fee for the removal of items by our waste provider. The local dump is located
                        near Renner Supply off State Route 208." />
                    <BulletPoint description="Trailers, work trucks, and campers/RVs are not to be parked in the
                        housing lanes/areas for longer than 3 days without management
                        approval. Please let us know if you need more time." />
                    <BulletPoint description="Storage sheds/lockers, prefab-built sheds etc. MUST be approved by
                        the management PRIOR to installation. No “makeshift” storage is
                        allowed, and tarps are NOT allowed to cover any items regardless of
                        fabric or color." />
                    <BulletPoint description="DO NOT FEED THE WILDLIFE in the park. Not only does this disrupt the
                        ecosystem of the area, but it also makes animals rely on you for food
                        and they stop foraging. Then when you can no longer feed them, some
                        animals have been known to starve to death because they relied on
                        your daily feeding. No deer stands, no hanging deer feeders, no corn
                        cobs for squirrels, and no throwing of food/leftovers about your
                        property for wildlife. Be extremely frugal with birdseed and hanging bird
                        feeders. We would prefer that you do not have them, but it is your
                        decision. Bears, mountain lions, raccoons, skunks, and coyotes have
                        all been spotted in our park recently so please follow these rules.
                        Violations may/could result in park eviction. We are not liable for
                        injuries or death caused by wildlife in our park." />
                    <BulletPoint description="No more than 10 gallons of gas or diesel can be stored at individual sites
                        and must be in lawful containers. All gas must be stored out of the view of tenants and
                        prospective tenants. No general explosives, detonation devices, or mining explosive equipment
                        are allowed, including fireworks." />
                    <BulletPoint description="A pathway of at least 10 feet wide MUST be left free of debris and
                        overgrowth of plant life along the riverbanks. Tenants are responsible
                        for maintaining this due to fire department rules. If you are physically
                        unable to maintain it, please let us know and we will hire (at your
                        expense) someone to come and clean up for you. This is mandatory for
                        our fire department regulations and there are NO EXCEPTIONS." />
                    <BulletPoint description="Smoking cigarettes and cigars is allowed at individual sites but please
                        be respectful of your neighbors. All cigarette/cigar butts must be
                        disposed of in a safe manner. NEVER put out your cigarettes/cigars on
                        the ground and NEVER flick cigarette/cigar butts and leave them
                        behind. Anyone caught littering cigarette butts on our property may be
                        removed from the park. Smoking INSIDE of any of Walker Wellington
                        LTD rental campers, RVs, mobile homes, tiny homes, restrooms or
                        laundry area is NOT ALLOWED. You must smoke cigarettes or cigars
                        outside if you are a renter. No EXCEPTIONS." />
                    <BulletPoint description="Both recreational / medical cannabis is legal in Nevada, but there are
                        several laws that cannabis users, both medical and recreational, MUST
                        FOLLOW ACCORDING TO NEVADA CANNIBIS LAWS. All cannabis
                        users must be 21 years of age, the cannabis must be purchased by a
                        state-licensed retail store and not grow your own, unless you reside
                        farther than 25 MILES FROM A DISPENSARY, you may possess up to 2.5
                        ounces of cannabis or ¼ ounce of resin cannabis. Use of cannabis
                        inside of privately owned units is at the owner’s discretion and
                        everyone must follow the law. If there are no retail cannabis stores
                        within 25 miles of your home- the nearest store to us is in Yerington and
                        it is 25.3 miles away - you may grow cannabis at your privately owned
                        home or rental with the parameters of only 6 plants per person (with no
                        more than 12 inside ANY household). All plants MUST BE grown within
                        a closet, a room, a greenhouse or other ENCLOSED area that is
                        equipped with a lock or security device, and the cannabis cannot be
                        visible to the public. THERE IS NO OUTDOOR/OPEN AIR GROWING
                        ALLOWED in Nevada for recreational or medical users by law. If we find
                        that you are breaking the law by growing cannabis outdoors, we will
                        contact the Lyon County Sheriff’s Office and you may be asked to leave
                        the property, be arrested, be evicted, or all the above. NO EXCEPTIONS." />
                    <BulletPoint description="All outdoor fires MUST be during a non-burn ban and all outdoor fires
                        MUST have a grated/screen fireproof cover over them and be kept at a
                        manageable size. No fire is allowed to be unattended and no fires next
                        to the flowing river unless the rules above are followed. Do NOT cut
                        down any park trees, regardless of where they are, dead or alive, to use
                        for firewood. Order wood through the Washoe Tribe or other local
                        vendors. All fires MUST be distinguished BEFORE you leave the area
                        and use the Smokey the Bear steps: Pour lots of water on it, make sure
                        there are no visible embers, stir the wood/ash with a shovel until the
                        material/land is cool, and then add more water before you leave. You
                        can also use dirt or sand in lieu of water." />
                    <BulletPoint description="No alterations to your sites are allowed, without previous approval from
                        management. NO FENCES on the front or the river side of property are
                        allowed to be constructed on any site. NO EXCEPTIONS. You may see
                        some fences at sites currently- they are “grandfathered” in and will not
                        be asked to remove them." />
                    <BulletPoint description="No hunting of any wildlife is allowed on park property or in the
                        surrounding private property areas within view of the park. No trapping
                        of wildlife is permitted except by a trained licensed professional. You
                        ARE allowed to fish behind your sites if you possess a valid fishing
                        license and do not disturb your neighbors. You may see non-residents
                        fishing in the river, walking up/down the river, and that is legal, they are
                        however not allowed to park vehicles in our park or start/stop fishing
                        anywhere within our park, and they are not allowed to use individual
                        sites as access." />
                    <BulletPoint description="Management reserves the right to enter any lot to inspect and ensure
                        compliance. We will make every effort to give you notice when we are
                        doing inspections with at least 24 hours’ notice. If the site is out of
                        compliance and tenants refuse to comply, management reserves the
                        right to possibly ask the tenant to leave the park/start eviction, and any
                        corrective work will be the monetary responsibility of the tenants." />
                    <BulletPoint description="Limbs, leaves and debris from cleaning your site is YOUR
                        RESPONSIBILITY to take to the burn pile at the back of the park located
                        across from day camping. DO NOT dump items into the river or if using
                        an electric blower DO NOT blow debris into the drive lanes or onto
                        neighboring property. DO NOT put leaves, limbs or debris into the
                        dumpster. If you are caught you will be charged a $100.00 fee for the
                        removal of items by our waste provider. The local dump is located near
                        Renner Supply off State Route 208. If there are limbs TOUCHING your
                        mobile home/trailer/RV please notify the management immediately
                        and we will have them cut-back at our expense. Upkeep of your site also includes those people
                        who are snowbirds or who are not here full time. You will need someone to come in and make sure
                        that your site is clear of debris, leaves and limbs MONTHLY. We can refer you to a local bonded
                        and insured company if you are unable to be onsite monthly to check, send a family or friend to
                        check, or live too far away. And of course, we will also notify you if we see any issues." />
                    <BulletPoint description="Upkeep of your site also includes those people who are snowbirds or who
                        are not here full time. You will need someone to come in and make sure that your site is clear
                        of debris, leaves and limbs MONTHLY. We can refer you to a local bonded and insured company if
                        you are unable to be onsite monthly to check, send a family or friend to check, or live too far
                        away. And of course, we will also notify you if we see any issues. Rental unit renters are NOT
                        ALLOWED to have friends, family or companies work on your units. You must go through the Walker
                        Wellington Office, and we will send assistance. No Exceptions." />
                    <BulletPoint description="If you need help maintaining your site, let us know and we can arrange
                        (at your expense) to have a licensed and bonded company come out
                        and clean your site." />
                    <BulletPoint description="Do not dig holes on your site without management approval due to
                        buried cables, pipes etc." />
                    <BulletPoint description="Political signs, political slogan flags/signs and political flags ARE
                        allowed but must be removed within 10 days of any local or national
                        election. No profanity allowed on any of the above and all verbiage
                        must not disparage ANY candidate. Any vandalism or tampering with
                        another person’s political signs during election season is cause for
                        immediate park removal, a police report will be taken, and we will
                        support any police charges regarding this. NO EXCEPTIONS." />
                    <BulletPoint description={<Typography variant="h6">Any “for sale” signs posted outside of any RV, camper, or mobile home
                        must include your phone number and must be approved by park
                        management PRIOR to posting. Any company owned real estate/realtor
                        signs MUST be approved by park management PRIOR to posting. You
                        may also use the kiosk near the mailboxes to post and inform others of
                        the for-sale RV, camper, or mobile home. <b>IMPORTANT: When selling
                        your mobile home to an offsite buyer that will live ONSITE, the
                        prospective new owners MUST be vetted PRIOR to the sale. We own
                        the land that the home sites are on, and this is our policy. They will
                        be treated as new tenants with background, credit and reference
                        checks PRIOR to your final sale. While we understand this may be
                        different than selling a home on buyer owned land, we want to
                        ensure the integrity of all prospective tenants and protect our
                        current tenants. There is also no subletting your owned/rented
                        property. Leases/Rental Agreements are for the original signers ONLY.</b></Typography>} />
                    <BulletPoint description="Porches/decks/pergolas etc. MAY be installed at the expense of the
                        tenants with PRIOR management approval. They will need to follow
                        local Lyon County permitting, be installed by a licensed and bonded
                        company/person, and not impede any other tenants’ views of the river
                        or the mountains. All items built onsite will remain on Walker
                        Wellington property once you vacate your site. You are not allowed to
                        remove any porches, decks, pergolas and take off our property, even if
                        you paid for the structure to be built with approval. No temporary
                        shade/pop-up tents/windsails are allowed to stay up for more than 3
                        days without management approval." />
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default IndividualSiteInfoCard;
